

























































import { apiTemplateLists, apiDelTemplate } from '@/api/application/print'
import { Component, Prop, Vue } from 'vue-property-decorator'
import LsPagination from '@/components/ls-pagination.vue'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class Template extends Vue {
    // 分页
    pager: RequestPaging = new RequestPaging()

    // 获取列表
    getList() {
        this.pager
            .request({
                callback: apiTemplateLists,
                params: {}
            })
            .catch(() => {
                this.$message.error('数据请求失败，刷新重载!')
            })
    }

    // 删除这个小票模版
    onDel(id: any) {
        apiDelTemplate({ id: id }).then(() => {
            // 删除成功就请求新列表
            this.getList()
        })
    }

    // 新增
    goTemplateAdd() {
        this.$router.push({
            path: '/print/edit_template',
            query: {
                mode: PageMode.ADD
            }
        })
    }

    // 编辑
    goTemplateEdit(id: any) {
        this.$router.push({
            path: '/print/edit_template',
            query: {
                id: id,
                mode: PageMode.EDIT
            }
        })
    }

    created() {
        this.getList()
    }
}
